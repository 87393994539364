var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "background-sheet d-flex flex-column align-center justify-start",
    attrs: {
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-card', {
    staticClass: "login-card",
    attrs: {
      "elevation": "24",
      "max-width": "80vw",
      "outlined": "",
      "raised": "",
      "tile": "",
      "width": "600"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 flex-column font-weight-bold primary--text justify-center"
  }, [_vm._v("WELCOME "), _c('v-divider', {
    staticClass: "mt-4",
    attrs: {
      "width": "20%"
    }
  })], 1), _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitLogin.apply(null, arguments);
      }
    }
  }, [_c('v-card-text', {
    staticClass: "pa-6 pt-0"
  }, [_c('v-sheet', {
    staticClass: "d-flex flex-column align-center justify-center mb-2",
    attrs: {
      "color": "transparent",
      "height": "2rem",
      "transition": "scale-transition",
      "width": "100%"
    }
  }, [_c('v-alert', {
    attrs: {
      "value": _vm.error !== null,
      "dense": "",
      "outlined": "",
      "text": "",
      "type": "error",
      "width": "100%"
    }
  }, [_c('v-sheet', {
    staticClass: "error--text",
    attrs: {
      "color": "transparent",
      "width": "100%"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.alertText())
    }
  })], 1)], 1), _c('v-text-field', {
    ref: "userField",
    attrs: {
      "label": "Username",
      "prepend-icon": "mdi mdi-account",
      "type": "text"
    },
    model: {
      value: _vm.username,
      callback: function callback($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _c('v-text-field', {
    attrs: {
      "append-icon": _vm.showPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye',
      "label": "Password",
      "prepend-icon": "mdi mdi-key",
      "type": _vm.showPassword ? 'text' : 'password'
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "d-flex flex-column align-center justify-center px-8 py-8"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "block": "",
      "color": "primary",
      "loading": _vm.submitted,
      "tile": "",
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submitLogin.apply(null, arguments);
      }
    }
  }, 'v-btn', _vm.btnState, false), [_vm._v(" LOGIN ")]), _c('p', {
    staticClass: "my-2"
  }, [_vm._v("-or-")]), _c('v-btn', {
    attrs: {
      "block": "",
      "color": "primary",
      "to": "/login/microsoft",
      "tile": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-microsoft-office")]), _vm._v(" LOGIN MICROSOFT ")], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }